import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/pages/HomePage/Home";
import Products from "./components/pages/ProductsPage/Products";
import Contacts from "./components/pages/ContactsPage/Contacts";
import Services from "./components/pages/ServicesPage/Services";
import Funds from "./components/pages/FundsPage/Funds";
import "./App.css";

function App() {
  return (
    <div className="app">
      <Router>
        <div className="app-content-wrap">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/services" component={Services} />
            <Route path="/funds" component={Funds} />
            <Route path="/contacts" component={Contacts} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
