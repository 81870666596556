export const products = [
  {
    _id: 1,
    image: "/images/Uvara.png",
    name: "Uvara",
    description:
      "UVARA is a smart mapping and GIS solution designed to integrate all aspects of your site’s infrastructure. UVARA creates rich, digital maps of underground, surface and overhead assets providing powerful visualization, analytics and archiving out of the box.",
    urlLink: "https://www.uvara.com",
    imgStart: "",
    alt: "Uvara",
  },
  {
    _id: 2,
    image: "/images/RealWear.png",
    name: "RealWear",
    description:
      "RealWear HMT-1 provides the foundation for Connected Worker programs. Global leaders in energy, manufacturing and automotive industries trust the HMT-1 to empower and connect their global workforce.",
    urlLink: "https://www.realwear.com/",
    imgStart: "start",
    alt: "Real Wear",
  },
  {
    _id: 3,
    image: "/images/TrackView.png",
    name: "TrackView",
    description:
      "This Strategic Management System is designed to manage and track the level at which an organisation's strategies and projects perform. This important management tool helps senior management ensure the achievement of an organisation’s objectives and Key Performance Indicators.",
    urlLink: "https://www.trackview.com/",
    imgStart: "",
    alt: "Track View",
  },
];

export const intro = {
  title: "Bespoken Product Solutions",
  subtitle: "",
  bodytext: `We offer a range of digital products that will meet the needs of your business. We tailor our recommendations to your requirements, offering a bespoke solutions package. Below, you'll find some examples of digital products we offer.`,
};
