import React from "react";
import { Link } from "react-router-dom";
import "./Service.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Service({ title, description, urlLink, icon }) {
  return (
    <>
      <div className="service">
        <Link to={{ pathname: urlLink }} target="_blank">
          <img src={`${imgUrl} ${icon}`} alt={`${title} icon`} />
        </Link>
        <div className="service-text">
          <Link to={{ pathname: urlLink }} target="_blank">
            {title}
          </Link>
          <p>{description}</p>
          {/* <Link to={{ pathname: urlLink }} target="_blank">
            FIND OUT MORE...
          </Link> */}
        </div>
      </div>
    </>
  );
}

export default Service;
