import React from "react";
import "./PageIntro.css";

function PageIntro({ title, subtitle, bodytext }) {
  return (
    <>
      <div className="pageintro">
        <h1>{title}</h1>
        <p>{bodytext}</p>
      </div>
    </>
  );
}

export default PageIntro;
