import React from "react";
import "./Aboutus.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Aboutus({ title, description, icon }) {
  return (
    <>
      <div className="aboutus">
        <div className="aboutus-icon">
          <img src={`${imgUrl} ${icon}`} alt={`${title} icon`} />
        </div>
        <div className="aboutus-text">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </>
  );
}

export default Aboutus;
