import React from "react";
import PageIntro from "./../../PageIntro";
import { funds, intro } from "./FundsData";
import "./Funds.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Funds() {
  return (
    <>
      <div className="funds">
        <div className="container">
          <div className="funds-intro">
            <PageIntro
              title={intro.title}
              subtitle={intro.subtitle}
              bodytext={intro.bodytext}
            />
          </div>
          <div className="funds-body">
            <img src={`${imgUrl}/images/Funds.png`} alt="Funds image" />
            <div className="funds-body-text">
              <p>{funds.para1}</p>
              <ul>
                {funds.list.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
              <p>{funds.para2}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Funds;
