import React from "react";
import Aboutus from "./Aboutus";
import { vision, mission } from "./HomeData";
import "./Home.css";

const { PUBLIC_URL: imgUrl } = process.env;
function Home() {
  return (
    <>
      <div className="home">
        <div className="container">
          <div className="home-image">
            <span>A 360&deg; solutions driven approach </span>
          </div>
          <div className="home-aboutus-container">
            <div className="home-aboutus">
              <Aboutus
                title={vision.title}
                description={vision.description}
                icon={vision.icon}
              />
              <Aboutus
                title={mission.title}
                description={mission.description}
                icon={mission.icon}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
