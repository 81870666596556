export const servcol1 = [
  {
    _id: 1,
    title: "ENTERPRISE GIS IMPLEMENTATION AND CONSULTANCY",
    description:
      "The portfolio of GIS services we offer includes GIS Architecture Design, Mobile, Web, and Augmented Reality GIS Application Development, and Geographic data modeling following international standards (OGC).",
    urlLink: "https://www.globalistic.tech/",
    icon: "/images/GIS.png",
  },
  {
    _id: 2,
    title: "MACHINE LEARNING",
    description:
      "Turn your data into a competitive advantage by developing machine learning and business intelligence solutions. This helps organizations in making their strategic and tactical business decisions.",
    urlLink: "https://www.globalistic.tech/",
    icon: "/images/MachineLearning.png",
  },
];

export const servcol2 = [
  {
    _id: 1,
    title: "AUGMENTED REALITY APPLICATION DEVELOPMENT",
    description:
      "Use Augmented Reality to visualise your data. We help our customers better envision their data, simplify the decision making process, increase efficiency, and gain new insights. The implementation of AR solutions precedes an ongoing cycle of product management, delivery, and integration with existing ecosystems.",
    urlLink: "https://www.globalistic.tech/",
    icon: "/images/AR.png",
  },
  {
    _id: 2,
    title: "WEB AND MOBILE APPLICATION DEVELOPMENT",
    description:
      "We build, create and maintain web and mobile applications including UI/UX design, user-centered front-end development, back-end development, data engineering, and hybrid and cross-platform technologies for both iOS and Android. We help our clients distinguish themselves in the digital market, transforming their ideas into solutions by creating value-driven, complex web and mobile applications.",
    urlLink: "https://www.globalistic.tech/",
    icon: "/images/WebApp.png",
  },
];

export const intro = {
  title: "Our Services",
  subtitle: "",
  bodytext: `Making use of the Agile approach, we aim to provide value to our users with tools designed to plan, track and discuss work across teams. We take a user-centred approach and iterate based on this at every phase of the development lifecycle.`,
};
