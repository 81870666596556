import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { MenuItems } from "./MenueItems";
import "./Header.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Header() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <IconContext.Provider value={{ color: "#122f53", size: "25px" }}>
        <div className="header">
          <div className="header-container container">
            <Link className="header-logo" to="/" onClick={closeMobileMenu}>
              <img src={`${imgUrl}/images/cinch-logo.png`} alt="" />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "header-menu active" : "header-menu"}>
              {MenuItems.map((item, index) => (
                <li key={index} className="header-item">
                  <NavLink
                    exact
                    to={item.url}
                    className={item.cName}
                    activeClassName={item.acName}
                    onClick={closeMobileMenu}
                  >
                    {item.label}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="header-banner"></div>
      </IconContext.Provider>
    </>
  );
}

export default Header;
