import React from "react";
import { address, email, mobile } from "./ContactData";
import Contact from "./Contact";
import { Link } from "react-router-dom";
import "./Contacts.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Contacts() {
  return (
    <>
      <div className="contacts">
        <div className="container">
          <div className="contacts-row">
            <Contact
              title={mobile.title}
              img={mobile.img}
              alt={mobile.alt}
              text={mobile.text}
            />
            <Contact
              title={email.title}
              img={email.img}
              alt={email.alt}
              text={email.text}
            />
          </div>
          <div className="contacts-row">
            <Contact
              title={address.title}
              img={address.img}
              alt={address.alt}
              text={address.text}
            />
            <Link to={{ pathname: address.location }} target="_blank">
              <img
                className="contacts-map"
                src={`${imgUrl}/images/cinchmap.jpg`}
                alt="Cinch Location Map"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contacts;
