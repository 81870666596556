import React from "react";
import Product from "./Product";
import PageIntro from "../../PageIntro";
import { products, intro } from "./ProductsData";
import "./Products.css";

function Products() {
  return (
    <>
      <div className="products">
        <div className="container">
          <div className="products-intro">
            <PageIntro
              title={intro.title}
              subtitle={intro.subtitle}
              bodytext={intro.bodytext}
            />
          </div>

          {products.map(prod => (
            <div className="products-line" key={prod._id}>
              <Product
                title={prod.name}
                description={prod.description}
                image={prod.image}
                urlLink={prod.urlLink}
                imgStart={prod.imgStart}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Products;
