export const funds = {
  title: "",
  icon: "/images/Funds.png",
  para1:
    "We cooperate with the world's best incubators and accelerators including: ",
  list: [
    "Alchemist",
    "Techstars",
    "500 startups",
    "Cornell Tech Center",
    "Plug and Play",
  ],
  para2:
    "These Incubators have launched Companies totaling over one trillion dollars over the last decade, including well-known unicorns such as Airbnb, Canva and Grab.",
};

export const intro = {
  title: "Fund Management",
  subtitle: "",
  bodytext:
    "Cinch Consulting cooperates with key incubators and accelerators to manage funds that invest in vetted companies and conduct due diligence studies. As part of Investment Committee, we assist our customers in selecting double vetted companies with the potential for 10X - 100X growth that feature a technology component.",
};
