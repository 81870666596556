import React from "react";
import { FaRegCopyright } from "react-icons/fa";
import { IoMdContacts } from "react-icons/io";
import { IconContext } from "react-icons/lib";
import { Link } from "react-router-dom";
import "./Footer.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Footer() {
  return (
    <>
      <IconContext.Provider value={{ className: "footer-icons" }}>
        <div className="footer">
          <div className="footer-copyright">
            <FaRegCopyright />
            <p>2020 CINCH Consulting, All Rights Reserved.</p>
          </div>
          <div>
            <Link className="footer-links" to="/contacts">
              <IoMdContacts />
              <span className="footer-span">Contact Us</span>
              {/* <img src={`${imgUrl}/images/Mail.png`} alt="Footer mail image" />
            <img src={`${imgUrl}/images/Phone.png`} alt="Footer phone image" /> */}
            </Link>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}

export default Footer;
