import React from "react";
import { Link } from "react-router-dom";
import "./Product.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Product({ imgStart, title, description, image, alt, urlLink }) {
  return (
    <>
      <div className="product">
        <div
          className="product-row"
          style={{
            display: "flex",
            flexDirection: imgStart === "start" ? "row-reverse" : "row",
          }}
        >
          <div className=" product-image-wrapper">
            <Link to={{ pathname: urlLink }} target="_blank">
              <figure>
                <img
                  className="product-image"
                  src={`${imgUrl} ${image}`}
                  alt={alt}
                />
              </figure>
            </Link>
          </div>

          <div className="product-body">
            <Link to={{ pathname: urlLink }} target="_blank">
              {title}
            </Link>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Product;
