export const MenuItems = [
  {
    label: "Home",
    url: "/",
    cName: "header-links",
    acName: "header-links-active",
  },
  {
    label: "Products",
    url: "/products",
    cName: "header-links",
    acName: "header-links-active",
  },
  {
    label: "Services",
    url: "/services",
    cName: "header-links",
    acName: "header-links-active",
  },
  {
    label: "Funds",
    url: "/funds",
    cName: "header-links",
    acName: "header-links-active",
  },
  {
    label: "Contacts",
    url: "/contacts",
    cName: "header-links",
    acName: "header-links-active",
  },
];
