import React from "react";
import "./Contact.css";

const { PUBLIC_URL: imgUrl } = process.env;

function Contact({ title, img, alt, text }) {
  return (
    <>
      <div className="contact">
        <div className="contact-row">
          <img src={`${imgUrl} ${img}`} alt={alt} />
          <div className="contact-text">
            <h1>{title}</h1>
            <span>{text}</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
