export const address = {
  title: "Address",
  text: `CINCH Consulting
            2611 SW 17th St.
            TOPEKA, KS 66604
            USA`,
  img: "/images/Address.png",
  alt: "address",
  location:
    "https://www.google.com/maps/place/2611+SW+17th+St,+Topeka,+KS+66604,+USA/@39.0366245,-95.7124904,17z/data=!3m1!4b1!4m5!3m4!1s0x87bf029569f59163:0xf5a349af8752a291!8m2!3d39.0366204!4d-95.7103017",
};

export const mobile = {
  title: "Phone Numbers",
  text: "+1 (913) 206–2353",
  img: "/images/Tel.png",
  alt: "mobile",
};

export const email = {
  title: "E-Mail",
  img: "/images/Email.png",
  alt: "Email",
  text: "cinchconsult@gmail.com",
};
