export const vision = {
  title: "Vision",
  icon: "/images/Vision.png",
  description: `Our holistic approach to consultancy puts your needs first. Our diverse skills-set ensures the 
  horizontal and vertical needs of your project are catered to across all phases. It's simple - we do the hard work, 
  so you don't have to.`,
};

export const mission = {
  title: "Mission",
  icon: "/images/Mission.png",
  description: `Cinch is a 360 degree consultancy, designed to manage a successful project from inception to completion.`,
};
