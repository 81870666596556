import React from "react";
import { servcol1, servcol2, intro } from "./ServicesData";
import Service from "./Service";
import PageIntro from "../../PageIntro";
import "./Services.css";

function Services() {
  return (
    <>
      <div className="services">
        <div className="container">
          <div className="services-intro">
            <PageIntro
              title={intro.title}
              subtitle={intro.subtitle}
              bodytext={intro.bodytext}
            />
          </div>
          <div className="services-details">
            <div className="services-col">
              {servcol1.map(serv => (
                <div key={serv._id}>
                  <Service
                    title={serv.title}
                    description={serv.description}
                    urlLink={serv.urlLink}
                    icon={serv.icon}
                  />
                </div>
              ))}
            </div>
            <div className="services-col">
              {servcol2.map(serv => (
                <div key={serv._id}>
                  <Service
                    title={serv.title}
                    description={serv.description}
                    urlLink={serv.urlLink}
                    icon={serv.icon}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
